/* .vjs-progress-control {
    pointer-events: none;
} */

/* video-js css start*/
/* .video-js {} */
:root {
    --playerWidth: 640px;
    --playerHeight: 360px;
}

body {
    font-family: sans-serif !important;
    font-size: 14px !important;
    line-height: 1.5;
}

.video-js .vjs-big-play-button {
    top: calc(50% - 50px) !important;
    left: calc(50% - 50px) !important;
}

/* video-js css end*/
.draggable_parent_div {
    /* background: blue; */
    /* width: 750px; */
    /* height: 500px; */
    position: relative;
    /* overflow: 'auto'  */
}

.draggable_child_div {
    cursor: pointer;
    resize: none;
    border: none;
    position: absolute;
    top: 0px;
    bottom: auto;
    /* padding: 1px; */
    /* width: 150px; */
    /* max-width: 500px; */
    /* max-height: 500px; */
    /* cursor: all-scroll; */
    display: none;
    z-index: 998;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: content-box !important;
    /* overflow-y: auto;
    overflow-x: hidden; */

}
.textPara{
    padding: 0 9px;
}
.draggable_child_div_cta {
    cursor: pointer;
    resize: none;
    overflow: none;
    border: none;
    position: absolute;
    top: 0px;
    bottom: auto;
    padding: 1px;
    /* width: 150px; */
    max-width: 500px;
    max-height: 500px;
    /* cursor: all-scroll; */
    display: block;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* overflow-y: auto;
    overflow-x: hidden; */

}
.formZindex{
    transform: translate(0px, 0px) !important;
    align-items: center;
    width: 100% !important;
    height: 100%;   
}
/* .formZindex section {
    display: flex;
    flex: auto;
} */
.customForm {
    /* top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: 0; */
    /* display: flex !important; */
    /* margin: 0 auto;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 10px;
    overflow: hidden;
    overflow-x: hidden;
    min-height: 220px;
    z-index: 9999 !important; */
}

/* .customForm:hover {
    overflow-y: auto;
} */

/* .newFormOverflow {
    
} */
.my-video-dimensions {
    position: relative !important;
}

.newFormOverflow {
    /* position: absolute;
    top: 6px;
    bottom: 0;
    left: 0;
    right: 0; */
    /* margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative; */

    /* position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100% !important; */
    display: flex;

    /* flex-wrap: wrap; */
    /* flex-wrap: wrap; */

    /* flex-direction: column; */

    /* height: auto !important; */
}

/* .newFormOverflow:hover {
    overflow-y: auto;
} */
.player_dyn_img {
    width: 100%;
    height: auto;
    pointer-events: none;
}

.player_dyn_img_label {
    text-align: center;
    color: #fff;
    background: black;
    font-size: 16px;
    opacity: 0.7;
}

.player_popup {
    background: #fff;
    position: absolute;
    top: 15%;
    left: 10%;
    right: 10%;
    height: auto;
    width: auto;
    z-index: 99999;
    border: 1px solid #00000052;
    color: #000;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    /* align-items: center; */
    border-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999999;
}

.player_popup_child {
    display: flex;
    flex-flow: column;
    align-items: center;
    /* justify-content: center; */
    /* padding: 20px; */
    height: max-content;
    overflow-y: auto;
    overflow-x: hidden;
    /* min-height: 50px; */
    padding: 5px 8px;
    text-align: center;
}

.player_popup_child p {
    margin: 10px 0;
}

.modal_cross {
    background-color: transparent;
    background: url('../../../public/assets/quizCross.png');
    position: absolute;
    top: -1px;
    right: 0px;
    border: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 40px;
    float: left;
    background-size: cover;
    cursor: pointer;
    /* height: 25%; */
    /* width: 100%; */
    /* z-index: 99; */
    /* color: #fff; */
    /* border-radius: 50% !important; */
    /* display: flex !important; */
    /* justify-content: center; */
    /* align-items: center; */
    /* font-size: 18px !important; */
    /* line-height: 20px !important; */
}

.vjs-progress-holder img {
    height: auto !important;
    margin: 0 !important;
    max-width: 30px;
    min-width: 16px;
}

.video-js .vjs-progress-control .vjs-progress-holder {
    margin: 0;
}

.vjs-progress-holder {
    display: flex;
    align-items: center;
}

.player_popup_branching {
    /* background: rgba(0, 0, 0, 0.9); */
    position: absolute;
    top: 0;
    /* bottom: 40px; */
    left: 0;
    /* right: 0; */
    /* width: max-content; */
    z-index: 9;
    color: #fff;
    /* justify-content: center; */
    /* border: 1px outset skyblue; */
    display: flex;
    /* margin: 0 auto; */
}

.branching_edit_btns {
    position: absolute;
    top: 50%;
    right: 0;
}

.branching_edit_btns button {
    border: 0;
    font-size: 14px;
    color: #fff;
    padding: 5px 5px;
}

.edit_btn_danger {
    position: absolute;
    top: 50%;
    right: 0;
    border: 0;
    color: #000;
    padding: 2px 12px;
    z-index: 99999;
    cursor: pointer;
}

/* Hotsport icons css Start*/
.shopping_02_cart_icon {
    position: relative;
    width: auto;
    /* max-width: 45px; */
    height: auto;
    /* cursor: pointer; */
    border-radius: 50%;
    background: #41abe5;
    /* box-shadow: 0 0 60px 10px white; */
    padding: 9px;
    z-index: 999;
}

.shopping_02_cart_icon:before {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    box-shadow: 0 0 10px 2px white;
}

.shopping_02_cart_icon:after {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    box-shadow: 0 0 10px 2px white;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    100% {
        transform: scale(2.3);
        opacity: 0;
    }
}

.shopping_02_cart_icon img {
    height: 28px;
    margin: 0 auto;
    width: 28px;
    display: flex;
}

/* Hotsport icons css End*/

/* Text container css */
.text-container {
    position: relative;
    width: auto;
    /* max-width: 45px; */
    height: auto;
    cursor: pointer;
    /* keep this property on item object as not every image will be clickable */
    /* border-radius: 10%; */
    background: rgb(255, 255, 255);
    /* box-shadow: 0 0 60px 10px white; */
    padding: 0 9px;
    width: 100%;
    height: auto;
    height: auto;
    overflow-y: auto;
    opacity: 1;
}

.text-button {
    position: relative;
    width: auto;
    /* max-width: 45px; */
    height: auto;
    cursor: pointer;
    /* keep this property on item object as not every image will be clickable */
    border-radius: 10%;
    background: #ffffffed;
    /* box-shadow: 0 0 60px 10px white; */
    padding: 9px;
    width: 100%;
    height: auto;
    /* overflow-y: scroll; */
    /* opacity: 1; */
}
.text-button
{
    background: #fff;
    color: #333;
    padding: 5px 8px !important;
    border: 1px solid #000;
    border-radius: 5px;
    /* font-size: 14px; */
    /* font-weight: 500; */
    box-shadow: 2px 3px 5px #0d4887;
    width: auto;

}
/* .text-container:before {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    border-radius: 22%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    box-shadow: 0 0 10px 2px white;
}

.text-container:after {
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid white;
    border-radius: 22%;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    box-shadow: 0 0 10px 2px white;
} */

.video-js .my-image {
    width: 25px;
    /* background: url(https://placekitten.com/38/28) center center no-repeat; */
    position: absolute;
    height: 25px;
    margin-top: -12px;
    z-index: 1;
}

.video-js .my-hotspot {
    width: 25px;
    /* background: url(https://placekitten.com/38/28) center center no-repeat; */
    position: absolute;
    height: 25px;
    margin-top: -12px;
    z-index: 1;
}

.video-js .my-text {
    width: 25px;
    /* background: url(https://placekitten.com/38/28) center center no-repeat; */
    position: absolute;
    height: 25px;
    margin-top: -12px;
    z-index: 1;
}

.video-js .my-form {
    width: 25px;
    /* background: url(https://placekitten.com/38/28) center center no-repeat; */
    position: absolute;
    height: 25px;
    margin-top: -12px;
    z-index: 1;
}

.first_video_btns {
    display: flex;
    flex-flow: column;
    padding: 6px 5px;
    border: 0;
    opacity: 0.7;
    transition: 0.7s;
    justify-content: space-between;
    align-items: center;
    margin: 0 8px;
    background: #ffffffbf;
    cursor: pointer;
    /* width: auto; */
    /* min-width: 60px; */
    max-width: 180px;
}

/* .first_video_btns:first-child {
    margin-left: 0;
}
.first_video_btns:last-child {
    margin-right: 0;
} */
.first_video_btns img {
    height: calc(var(--playerHeight)/5);
    width: calc(var(--playerWidth)/7);
}

.first_video_btns:hover {
    background: #fffffff5;
}

/* 
.first_video_btns img {
    width: 55px;
} */
.first_video_btns span {
    color: #000;
    font-size: 13px;
    line-height: 20px;
    padding: 5px 0 0;
    text-align: center;
}

.branching_icons {
    display: flex;
    flex-flow: row;
    /* justify-content: center; */
    /* overflow: auto; */
    margin: 0 auto;
    z-index: 999;
    position: relative;
}

.formQuizRender {
    padding: 0 10px;
    font-family: sans-serif;
    width: 100%;
}

.quizRenderBg {
    background: #fff;
    /* width: 350px; */
}

.formQuizRender h2 {
    font-size: 18px;
    color: #043348;
    margin: 6px 0px 8px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #0000001a;
    /* text-align: center; */
}

.formQuizRender h3 {
    font-size: 16px;
    text-transform: capitalize;
    margin: 5px 0;
    color: #000000ba;
}

.formQuizRender p {
    display: block;
    font-size: 17px;
    margin: 8px 0;
    text-transform: capitalize;
}
.formQzCom input[type="radio"]{
    margin-top: 0;
}
.formQuizRender span {
    font-size: 15px;
    display: block;
    width: 100%;
    margin-top: 0;
}
.formQzCom h5{
    margin: 3px 0 3px 0;
}
.formQuizRender button {
    max-width: 150px;
    width: auto;
    height: auto;
    padding: 8px;
    font-size: 16px;
    margin: 10px 0 0 0;
    color: #fff;
    background: #043348;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border: 1px solid #043348;
    cursor: pointer;
}

.config_poster_inp {
    width: -webkit-fill-available;
    box-shadow: 0 1px 2px 0 rgb(29 37 45 / 10%) !important;
    border: solid 1px rgba(127, 125, 142, 0.1);
    font-size: 15px !important;
    height: 50px;
    padding: 0px 17px;
    margin: 5px 0px 0px 0;
}

.config_poster_inp:focus {
    outline: none;
}

.formQzBg {
    background: #fff;
    /* width: 350px;
    height: 400px; */
    overflow-y: auto;
    overflow-x: hidden;
}

.quizCross {
    position: absolute;
    top: 0;
    right: 3px;
    width: 20px;
    height: 20px;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background: url('../../../public/assets/quizCross.png');
    z-index: 99999;
}

.quizCrossQuiz {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background: url('../../../public/assets/quizCross.png');
    z-index: 99999;
}
.fieldsLabel{
    margin: 6px 0 10px 0;
    font-weight: 600;
    color: #222;
    display: block;
}

.fieldsLabel,
.fieldOptions {
    background: #fff;
}

.template_div {
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
}

/* .car_color_img {
    justify-content: center;
    display: flex;
} */
/* .formQzBg {
    height: 420px;
    overflow-y: scroll;
} */
.video-js .vjs-progress-control {
    z-index: 0;
}

.fieldMandatory {
    border: 1px solid red;
    /* margin: 0 auto; */
    /* width: 300px; */
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.fieldErrorMsg {
    position: relative;
}

.mandatoryMsg {
    line-height: 0px;
    color: red;
    position: relative;
    /* top: 2px; */
    font-size: 11px !important;
    width: 100%;
}

.btnBlue {
    background: #589bff;
    padding: 5px 12px;
    cursor: pointer;
    z-index: 99999;
    color: #fff;
    border-radius: 50px;
}
.btnBlue img {
    width: 15px;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #000;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(3deg, #11919c, #000851);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(3deg, #000851, #11919c);
}

.parentOverlay:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000036;
    top: 0;
    left: 0;
    z-index: 997;
}

.cta_button {
    background: #fff;
    color: #333;
    padding: 5px 8px;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 2px 3px 5px #0d4887;
}

.cta_button p {
    margin: 0;
}

.customizedCTA_1 {
    display: flex;
    align-items: center;
    flex-flow: row;
    background: #4b4943de;
    width: max-content;
    padding: 6px 7px;
    border-radius: 50px;
}

.customizedCTAImg_1 {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    pointer-events: none;
}

.customizedCTATxt_1 {
    font-size: 15px;
    font-weight: bold;
    padding: 0 10px;
    color: #fff;
    max-width: 160px;
    width: auto;
}

.customizedCTABtn_1 {
    background: #003279;
    color: #fff;
    border: 0;
    border-radius: 50px;
    width: auto;
    height: auto;
    padding: 10px 20px;
    /* text-transform: uppercase; */
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
}

.customizedCTA_2 {
    display: flex;
    align-items: center;
    flex-flow: row;
    background: #fffc;
    width: max-content;
    padding: 6px 7px;
    border-radius: 10px;
}

.customizedCTAImg_2 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    pointer-events: none;
}

.customizedCTATxt_2 {
    font-size: 15px;
    font-weight: bold;
    padding: 0 10px;
    color: #333;
    max-width: 160px;
    width: auto;
}

.customizedCTABtn_2 {
    background: #0d6efd;
    color: #fff;
    border: 0;
    border-radius: 50px;
    width: auto;
    height: auto;
    padding: 10px 20px;
    /* text-transform: uppercase; */
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
}

.quizFormStyle {
    position: absolute;
    top: 10px;
    background: #efb70f;
    z-index: 99999;
    color: #333;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 5px;
    z-index: 9999999;
}

.quizFormStyle p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.quizFormStyle span {
    font-size: 25px;
    cursor: pointer;
}

/* .first_video_btns:nth-child(odd) {
   
}
.first_video_btns:nth-child(even) {
    background: #66a8ccb3;
} */

.branchingVertical {
    flex-flow: column;
}

.branchingVertical .first_video_btns {
    margin: 3px 0;
}

.img_branching {
    background: #ffffffbf;
    padding: 5px;
    opacity: .7;
}
.player_dyn_img_label_branching {
    text-align: center;
    color: black;
    background: #ffffffbf;
    font-size: 16px;
    opacity: .7;
}
.shopping_02_images_grid{
    line-height: 0.5 !important;
}
.player_popup_child{
    padding: 10px !important;
}
*{
    box-sizing: border-box;
}
#car_modal_box_1 p {
    margin-top: 0;
}
#car_modal_box_1 h2{
    margin-top: 0; 
}
.car_modal_detail3 ul {
    margin-top: 0;
}
#car_modal_box_3 h2 {
    margin-top: 2px;
}
.hideAutoFixedTemp{
    visibility: hidden;
}
#BranchingTemplateCont{
    height:100%;
}