@media screen and (min-width:0) and (max-width:320px){
    .customizedCTA_1{
        padding: 3px 2px;   
    }    
    .customizedCTAImg_1{
        width: 30px !important;
        height: 30px !important;
    }
    .customizedCTATxt_1{
        font-size: 11px;
        max-width: 130px;
        padding: 0 3px 0 3px;
    }
    .customizedCTABtn_1{
        font-size: 10px;
        padding: 4px 2px;
        margin-left: 0px;
    }
}
@media screen and (min-width:321px) and (max-width:480px) {
    .customizedCTA_1 {
        padding: 4px 3px;
    }
    .customizedCTAImg_1 {
        width: 40px;
        height: 40px;
    }
    .customizedCTATxt_1 {
        font-size: 11px;
        max-width: 150px;
        padding: 0 2px 0 4px;
    }
    .customizedCTABtn_1 {
        font-size: 11px;
        padding: 7px 10px;
    }
}