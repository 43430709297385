.panel_side_icons ul {
    list-style: none;
    display: flex;
    flex-flow: column;
    height: 580px;
    overflow-y: auto;
    padding-left: 0;
    margin: 0 10px 0 0px;

}

.panel_side_icons ul li img {
    width: 40px;
}

.panel_side_icons ul li {
    text-align: center;
    margin: 14px 0;
    cursor: pointer;
}

.panel_side_icons ul li span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.panel_body_fl {
    margin-bottom: 50px;
    display: flex;
    flex-flow: row;
    /* margin-top: 10px; */
    /* padding: 0 14px; */
}

.panel_side_icons ul li:hover span {
    color: #2187a3;
}

.form_opt_images img {
    width: 30px;
}

.form_opt_images span {
    display: block;
}

.form_options {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    padding: 20px 10px;
    margin: 15px 40px;
    border: 1px solid #0000000d;
}

.form_opt_edit img {
    width: 20px;
    margin: 0 6px;
    cursor: pointer;
}

.form_opt_images_txt {
    display: flex;
    flex-flow: column;
    margin-right: 40px;
}

.form_opt_images_txt p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

.form_opt_images_txt span {
    font-size: 15px;
    margin: 5px 0;
}

.form_opt_images_txt button {
    background: #f5f5f5;
    border: 0;
    padding: 8px 12px;
    cursor: pointer;
    margin-top: 8px;
}

.panel_form {
    width: 100%;
    height: 550px;
    overflow: auto;
}

.panel_form h2{
    text-align: center;
}

.form_options:hover {
    background: #00000005;
}

.form_options:hover .form_opt_images_txt button {
    background: #48b4e5;
    color: #fff;
}

.panel_side_icons ul::-webkit-scrollbar {
    width: 7px;
}

.panel_side_icons ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #88888870;
    border-radius: 10px;
}

.panel_side_icons ul::-webkit-scrollbar-thumb {
    background: #88888870;
    border-radius: 10px;
}

.panel_side_icons ul::-webkit-scrollbar-thumb:hover {
    background: rgba(115, 133, 159, 0.75);
}

.panel_form::-webkit-scrollbar {
    width: 8px;
}

.panel_form::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #88888870;
    border-radius: 10px;
}

.panel_form::-webkit-scrollbar-thumb {
    background: #88888870;
    border-radius: 10px;
}

.panel_form::-webkit-scrollbar-thumb:hover {
    background: rgba(115, 133, 159, 0.75);
}

.search_bar {
    width: 200px;
    height: 36px;
    border: 1px solid #d7cccc;
    padding: 0 0 0 12px;
    border-radius: 5px;
    margin: 0 auto;
    display: flex;
}

/* modal_css */
.modal{
    --bs-modal-width: 45% !important;
}
.modal-header .btn-close {
    position: absolute;
    top: 4%;
    left: auto;
    right: 20px;
}